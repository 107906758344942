import React from "react";
import Layout from "../../Layout";
import SEO from "../../seo";
import "./style.scss";

export default function Connect() {
  return (
    <Layout>
      <div className="connect">
        <h1 className="heading">Contact Information</h1>
        <SEO
          title="Contact information | Chinmaya Vidyalaya Vasant Vihar"
          description="View the school address and contact details for general queries and admission information. Submit enquiries via the contact form."
          img="https://www.chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
          keywords="contact chinmaya vidyalaya delhi, chinmaya vidyalaya vasant vihar, chinmaya mission,"
        />
        <div className="connect__container">
          <div className="row">
            <div className="col col-12 col-lg-6">
              <h3>Address:</h3>
              Chinmaya Vidyalaya Near Priya Cinema, Indian Air Lines & Air India
              Estate, Vasant Vihar, New Delhi, Delhi 110057
              <h3>Phone:</h3>
              +91 11 2614 7020, 2614 2958, 2614 2756
              <h3>General Information:</h3>
              admin@chinmayavvdelhi.ac.in
              <h3>Admissions:</h3>
              admission@chinmayavvdelhi.ac.in
              {/* <h3>ERP Technical Assistance:</h3>
              tech@chinmayavvdelhi.ac.in */}
            </div>
            <div className="col col-12 col-lg-6">
              <div className="connect__map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14017.809872755222!2d77.1582007!3d28.5561728!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x412ebeae9593273f!2sChinmaya%20Vidyalaya!5e0!3m2!1sen!2sin!4v1633931929838!5m2!1sen!2sin"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
